<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Ajoute une boutique</ion-title>
        <ion-buttons slot="end">
      </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">


     <ion-item>
        <ion-label position="stacked">Titre</ion-label>
        <ion-input v-model="formInfo.nom"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Description</ion-label>
        <ion-textarea v-model="formInfo.description" rows="4"></ion-textarea>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Photo</ion-label>
        <ion-img :src="formInfo.photo"></ion-img>
        <ion-button @click="takePhoto()">Ajouter une photo</ion-button>
      </ion-item>

     <ion-item>
        <ion-label position="stacked">Rue</ion-label>
        <ion-input v-model="formInfo.rue"></ion-input>
      </ion-item>
     <ion-item>
        <ion-label position="stacked">Code Postal</ion-label>
        <ion-input v-model="formInfo.cp"></ion-input>
      </ion-item>
     <ion-item>
        <ion-label position="stacked">Ville</ion-label>
        <ion-input v-model="formInfo.ville"></ion-input>
      </ion-item>

<ion-card>
  <ion-card-header>
    <ion-item>
      <ion-card-title>Les horaires</ion-card-title>
     </ion-item>
  </ion-card-header>
  <ion-card-content>
    <ion-list>

    <ion-item>
        Lundi
   </ion-item>
      <ion-item>
          <ion-label>Journéee Continue ?</ion-label>
          <ion-toggle id="checkLundi" slot="end"></ion-toggle>
      </ion-item>

            <ion-item>
          <ion-label>Ouverture</ion-label>
          <ion-datetime display-format="h:mm A" picker-format="h:mm A" value="1990-02-19T07:43Z"></ion-datetime>
        </ion-item>
                <ion-item >
          <ion-label>Fermeture midi</ion-label>
          <ion-datetime display-format="h:mm A" picker-format="h:mm A" value="1990-02-19T07:43Z"></ion-datetime>
        </ion-item>
            <ion-item>
          <ion-label>Ouverture apres midi</ion-label>
          <ion-datetime display-format="h:mm A" picker-format="h:mm A" value="1990-02-19T07:43Z"></ion-datetime>
        </ion-item>
                <ion-item>
          <ion-label>Fermeture Soir</ion-label>
          <ion-datetime display-format="h:mm A" picker-format="h:mm A" value="1990-02-19T07:43Z"></ion-datetime>
        </ion-item>

   </ion-list>
  </ion-card-content>
     </ion-card>

<ion-card>
  <ion-card-header>
  <ion-item>
    <ion-card-title>Définition des créneaux</ion-card-title>
    <ion-button slot="end" @click="formInfo.horaires.push({type:null,duree:null})">ajouter un créneau</ion-button>
    </ion-item>
  </ion-card-header>
        <ion-card-content>
        <ion-list>
          <ion-item v-for="(hor,index) in formInfo.horaires" :key="index">
          <ion-input v-model="hor.type" placeholder="Entrer le type"></ion-input>
           <ion-select placeholder="Choisir une durée" v-model="hor.duree">
             <ion-select-option value="10">10 minutes</ion-select-option>
             <ion-select-option value="20">20 minutes</ion-select-option>
           </ion-select>
           <ion-button>Effacer</ion-button>
          </ion-item>
       </ion-list>
        </ion-card-content>
     </ion-card>


     <ion-item>
        <ion-button @click="sauver()">Sauver</ion-button>
      </ion-item>

   </ion-content>
  </ion-page>
</template>
<script>

//import cuid from 'cuid';

var cuid = require('cuid');

import {  archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, add } from 'ionicons/icons';


import {ref, computed, watch, reactive, onMounted} from 'vue'

import { useRoute, useRouter } from 'vue-router';
import shared from "../shared/shared";



export default {
  name: "BoutiqueCreer",
  inject:{shared: "shared"},
setup(props, context){
  //alert(cuid())
  const router = useRouter();
  const route = useRoute();

  const formInfo=reactive({
    _id:null,
    type:"bout",
      nom:"Nom boutique",
      description:"",
      photo:null,
      horaires:[]
  });

const sauver = () => {
  //alert('ici');
  //alert (JSON.stringify(formInfo));
  if (formInfo._id){

  } else {
  formInfo.type="boutique";
  formInfo._id=cuid()+':0';
  }
 //alert('la')

  shared.localdb.put(formInfo)
  //shared.create(formInfo)
  .then(function (response) {
  // handle response
  //alert(response)
    //rediriger versd la vue
    router.replace({path:'/jsc/Boutique/'+response.id});
}).catch(function (err) {
  alert(err);
});

};

const takePhoto = async () => {
  //alert('ici')
  let pp =  await shared.takePhot();
  //alert('ici')
  //alert('je reduis')

 formInfo.photo = pp
 //alert(JSON.stringify(formInfo))
}

return {
 //computed
//function
formInfo,
route,
router,
add,
sauver,
takePhoto
}
}
}
</script>

<style scoped>

</style>
